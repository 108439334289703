var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tile is-ancestor"},_vm._l((_vm.timetable),function(period,periodIndex){return _c('div',{key:period.periodIdent + '/' + period.groupIdent,staticClass:"tile is-parent is-2"},[_c('article',{staticClass:"media tile is-child box",class:_vm.getClassForPeriod(periodIndex)},[_c('div',{staticClass:"media-left is-size-1"},[_c('Icon',{staticClass:"is-64x64",attrs:{"icon":"mdi:timetable"}})],1),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content",class:period.isAbsence ? 'has-background-danger-light' : '',staticStyle:{"height":"100%"}},[_c('p',[_c('strong',[_vm._v(_vm._s(period.description))]),_c('br'),_c('small',[_vm._v(_vm._s(period.roomName))]),(!_vm.$store.getters.isStaff)?_c('small',[_c('br'),_vm._v(_vm._s(period.staffName))]):_vm._e(),_c('br'),_vm._v(" "+_vm._s(period.startTime)+" - "+_vm._s(period.endTime)+" "),(_vm.$store.getters.isStaff)?_c('span',[(period.groupIdent)?_c('router-link',{attrs:{"to":{
                  path:
                    '/school/timetable/group/' +
                    period.groupIdent.toString() +
                    '/' +
                    period.periodIdent +
                    '/' +
                    period.date +
                    '/',
                }}},[_vm._v("more")]):_vm._e()],1):_vm._e()]),(period.others && period.others.length)?_c('hr'):_vm._e(),(period.others && period.others.length)?_c('p',{staticClass:"has-text-centered is-size-6"},[_vm._v(" Cover ")]):_vm._e(),_vm._l((period.others),function(periodA){return _c('p',{key:periodA.description + periodA.isCover},[_c('strong',[_vm._v(_vm._s(periodA.description))]),_c('br'),_c('small',[_vm._v(_vm._s(periodA.roomName)),(!_vm.$store.getters.isStaff)?_c('span',[_vm._v("- "+_vm._s(periodA.staffName))]):_vm._e()]),_c('br'),_vm._v(" "+_vm._s(periodA.startTime)+" - "+_vm._s(periodA.endTime)+" "),_c('br'),(_vm.$store.getters.isStaff && periodA.notes)?_c('span',[_vm._v("For: "+_vm._s(periodA.notes))]):_vm._e(),(_vm.$store.getters.isStaff && periodA.notes2)?_c('span',[_vm._v("By: "+_vm._s(periodA.notes2))]):_vm._e(),_c('br'),(_vm.$store.getters.isStaff)?_c('span',[(periodA.groupIdent)?_c('router-link',{attrs:{"to":{
                  path:
                    '/school/timetable/group/' +
                    periodA.groupIdent.toString() +
                    '/' +
                    periodA.periodIdent +
                    '/' +
                    periodA.date +
                    '/',
                }}},[_vm._v("more")]):_vm._e()],1):_vm._e()])})],2)])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }