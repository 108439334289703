<template>
  <div class="tile is-ancestor">
    <div
      v-for="(period, periodIndex) in timetable"
      :key="period.periodIdent + '/' + period.groupIdent"
      class="tile is-parent is-2"
    >
      <article
        class="media tile is-child box"
        :class="getClassForPeriod(periodIndex)"
      >
        <div class="media-left is-size-1">
          <Icon icon="mdi:timetable" class="is-64x64" />
        </div>
        <div class="media-content">
          <div
            class="content"
            :class="period.isAbsence ? 'has-background-danger-light' : ''"
            style="height: 100%"
          >
            <p>
              <strong>{{ period.description }}</strong
              ><br />
              <small>{{ period.roomName }}</small>
              <small v-if="!$store.getters.isStaff"
                ><br />{{ period.staffName }}</small
              >
              <br />
              {{ period.startTime }} - {{ period.endTime }}
              <span v-if="$store.getters.isStaff">
                <router-link
                  v-if="period.groupIdent"
                  :to="{
                    path:
                      '/school/timetable/group/' +
                      period.groupIdent.toString() +
                      '/' +
                      period.periodIdent +
                      '/' +
                      period.date +
                      '/',
                  }"
                  >more</router-link
                >
              </span>
            </p>
            <hr v-if="period.others && period.others.length" />
            <p
              v-if="period.others && period.others.length"
              class="has-text-centered is-size-6"
            >
              Cover
            </p>
            <p
              v-for="periodA in period.others"
              :key="periodA.description + periodA.isCover"
            >
              <strong>{{ periodA.description }}</strong
              ><br />
              <small
                >{{ periodA.roomName
                }}<span v-if="!$store.getters.isStaff"
                  >- {{ periodA.staffName }}</span
                ></small
              >
              <br />
              {{ periodA.startTime }} - {{ periodA.endTime }}
              <br />
              <span v-if="$store.getters.isStaff && periodA.notes"
                >For: {{ periodA.notes }}</span
              >
              <span v-if="$store.getters.isStaff && periodA.notes2"
                >By: {{ periodA.notes2 }}</span
              >
              <br />
              <span v-if="$store.getters.isStaff">
                <router-link
                  v-if="periodA.groupIdent"
                  :to="{
                    path:
                      '/school/timetable/group/' +
                      periodA.groupIdent.toString() +
                      '/' +
                      periodA.periodIdent +
                      '/' +
                      periodA.date +
                      '/',
                  }"
                  >more</router-link
                >
              </span>
            </p>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import format from "date-fns/format";

export default {
  name: "RTT",

  components: {
    Icon,
  },

  props: ["timetable"],

  methods: {
    getClassForPeriod(periodIndex) {
      let period = this.timetable[periodIndex];

      let today = format(new Date(), "yyyy-MM-dd HH:mm");

      if (period.date !== today.slice(0, 10)) {
        return "";
      }

      let time = today.slice(-5);

      if (time < period.startTime) {
        return "";
      }

      if (time > period.endTime) {
        return "";
      }

      return "notification is-info";
    },
  },
};
</script>
