<template>


  <div class="container has-background-white-ter">
    <section id="users-timetable" class="section">
      <div class="container">
        <div class="box"
          style="align-content: flex-start; flex-direction: row; justify-content: space-between; display: flex;">

          <h4 class="title is-4 is-spaced">Timetable</h4>

          <select v-if="hasTimetables" v-model="currentTeacher" style="font-size: 1.5em">
            <option v-for="option in teachers" :key="option.pIdent" v-bind:value="option.pIdent">
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>
    </section>


    <section id="users-timetable" class="section">
      <div class="container" v-if="loaded">
      <RTT v-if="hasTimetables" :timetable="timetables[currentTeacher]?.details"></RTT>
      <p class="is-2" v-else>No timetables available</p>
    </div>
    <ModalProgress v-else message="Retrieving from your MIS - can take several seconds" title="Loading...." />

    </section>


  </div>
</template>

<script>

import RTT from "Components/school/rtt.vue";
import ModalProgress from "Components/ModalProgress.vue";


export default {
  name: "home",

  components: {
    ModalProgress,
    RTT
  },

  data: function () {
    return {
      timetables: {},
      teachers: [],
      hasTimetables: false,
      loaded: false,
      currentTeacher: this.$store.getters.currentUserDetails?.StaffId || null
    };
  },


  mounted() {
    //    console.log([process.env.VUE_APP_API_BASE, 123])

    this.timetables = {}
    this.teachers = []
    this.loaded = false;
    this.currentTeacher = this.$store.getters.currentUserDetails?.StaffId
    this.hasTimetables = false

    this.$api.get("v2/staff/timetable/rtt/all/", true, true).then((response) => {

      let rtn = {};

      if (!response) {
        this.timetables = rtn
        this.loaded = true
        return
      }

      Object.values(response).forEach(teacher => {

        if (!teacher.details || teacher.details.length == 0) {
          return;
        }

        if (teacher.details.filter(x => x.groupIdent).length == 0) {
          return;
        }

        this.teachers.push({ pIdent: teacher.ident, name: teacher.displayName })

        //console.log(this.teachers)

        teacher.details.sort(function (a, b) {

          if (a.startTime < b.startTime) {
            return -1
          }
          if (a.startTime > b.startTime) {
            return 1
          }
          return 0
        })

        teacher.details = teacher.details.reduce((p, c) => {

          if (p.length == 0) {
            return [c];
          }

          if (c.periodIdent != p.at(-1).periodIdent) {
            p.push(c)
            return p
          }

          let d = p.pop()

          if (!d.others) {
            d.others = [c]
          }
          else if (d.others.length && (d.others.at(-1).groupIdent != c.groupIdent)) {
            d.others.push(c)
          }

          p.push(d)


          return p

        }, [])

        rtn[teacher.ident] = teacher
        this.hasTimetables = true
      })

      this.timetables = rtn
      this.loaded = true

    });
  },
};
</script>
